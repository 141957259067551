import { PropTypes } from "prop-types";

import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";

import * as Styles from "./categories/styles.module.scss";

function Categories({ categories, refine, currentRefinement, nbLinks }) {
  const dict = useLocalizedSentenceDict();

  let additionalClassName = "firstPage";
  let showTitle = true;
  if (currentRefinement) {
    additionalClassName = "secondPage";

    if (nbLinks === 0) {
      showTitle = false;
    }
  }

  return (
    <div className={`${Styles.categoriesContainer} ${Styles[additionalClassName]}`}>
      {showTitle && <h3 className="h3 h3--bold h3--uc">{dict.get("Top Searches")}</h3>}
      <div className={Styles.categories} data-testid="categories">
        {categories.map((keyword) => (
          <button
            type="button"
            key={keyword}
            className={`text ${Styles.categories_item}`}
            onClick={() => refine(keyword)}
          >
            {dict.get(keyword)}
          </button>
        ))}
      </div>
    </div>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  refine: PropTypes.func,
  currentRefinement: PropTypes.string,
  nbLinks: PropTypes.number,
};

export default Categories;
