import { useStaticQuery, graphql } from "gatsby";
import { useContext } from "react";
import { DiscountContext } from "../context/DiscountProvider";

function useSalesColors() {
  const { discountInfo } = useContext(DiscountContext);

  const data = useStaticQuery(graphql`
    query {
      saleColors: allContentfulSaleColor {
        edges {
          node {
            discountCode
            hpButtonBgColorDesktop
            hpBgColorDesktop
            hpButtonHoverBgColorDesktop
            hpTextColorDesktop
            hpBgColorMobile
            hpButtonBgColorMobile
            hpButtonHoverBgColorMobile
            hpTextColorMobile
            tierBgColorGuest
            tierTextColorGuest
            tierInverseTextColorGuest
            buttonBackgroundColorGuest
            buttonTextColorGuest
            tierBgColorLuxe
            tierTextColorLuxe
            tierInverseTextColorLuxe
            buttonBackgroundColorLuxe
            buttonTextColorLuxe
          }
        }
      }
    }
  `);

  const dataSaleColors = data?.saleColors?.edges.reduce((acc, col) => {
    acc[col.node.discountCode] = col.node;
    return acc;
  }, {});

  dataSaleColors.default = {
    hpBgColorDesktop: "#E4E1DB",
    hpBgColorMobile: "#E4E1DB",
    hpButtonBgColorDesktop: "#000000",
    hpButtonBgColorMobile: "#000000",
    hpButtonHoverBgColorDesktop: "#FFFFFF",
    hpButtonHoverBgColorMobile: "#FFFFFF",
    hpTextColorDesktop: "#FFFFFF",
    hpTextColorMobile: "#FFFFFF",
  };

  try {
    return dataSaleColors[discountInfo?.code];
  } catch (error) {
    return dataSaleColors.default;
  }
}

export default useSalesColors;
