// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--3c5fb";
export var bold = "styles-module--bold--3fe80";
export var center = "styles-module--center--4f708";
export var firstPage = "styles-module--first-page--b8c78";
export var h1 = "styles-module--h1--b395f";
export var h1Bold = "styles-module--h1--bold--76bd7";
export var h1Uc = "styles-module--h1--uc--a94c3";
export var h2 = "styles-module--h2--cbc00";
export var h2Bold = "styles-module--h2--bold--860a5";
export var h2Uc = "styles-module--h2--uc--90eb3";
export var h3 = "styles-module--h3--c5f84";
export var h3Bold = "styles-module--h3--bold--67f34";
export var h3Uc = "styles-module--h3--uc--7d476";
export var h4 = "styles-module--h4--21335";
export var h4Bold = "styles-module--h4--bold--6df16";
export var h4Uc = "styles-module--h4--uc--3f8b0";
export var linkUnderline = "styles-module--link--underline--2c34c";
export var linksCarousel = "styles-module--links-carousel--0ea82";
export var linksCarouselContainer = "styles-module--links-carousel-container--d5437";
export var microtext = "styles-module--microtext--9a4fe";
export var microtextBold = "styles-module--microtext--bold--d14b7";
export var microtextUc = "styles-module--microtext--uc--0ddb5";
export var secondPage = "styles-module--second-page--58c3d";
export var strikethrough = "styles-module--strikethrough--a2acd";
export var strikethroughLg = "styles-module--strikethrough--lg--ed387";
export var strikethroughSm = "styles-module--strikethrough--sm--7a265";
export var subtext = "styles-module--subtext--6f4c5";
export var subtextBold = "styles-module--subtext--bold--36b3e";
export var subtextUc = "styles-module--subtext--uc--81d44";
export var text = "styles-module--text--7fb31";
export var textBold = "styles-module--text--bold--5b485";
export var textUc = "styles-module--text--uc--ff8dc";
export var title = "styles-module--title--42ed8";
export var titleL = "styles-module--title-l--3c129";
export var titleM = "styles-module--title-m--45b70";
export var titleS = "styles-module--title-s--d9972";
export var titleXl = "styles-module--title-xl--d327f";
export var titleXs = "styles-module--title-xs--256b9";
export var titleXxs = "styles-module--title-xxs--b9d70";
export var uc = "styles-module--uc--3be5d";
export var underline = "styles-module--underline--be3c1";