import { useMemo, useState } from "react";
import algoliaSearch from "algoliasearch/lite";
import aa from "search-insights";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { PropTypes } from "prop-types";

import { isBrowser } from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import CustomSearchUI from "./searchPopup/CustomSearchUI";

import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useCurrentLang } from "../../hooks/useLocalizedCartInfo";
import { getAlgoliaIndexBaseKey } from "../../../locale-shopifies";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import * as Styles from "./searchPopup/styles.module.scss";

const searchClient = algoliaSearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);

aa("init", {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  apiKey: process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
});

function SearchPopup({ closeSearch }) {
  const [sortBy, setSortBy] = useState("");

  const countryCode = useCurrentCountryCode();
  const lang = useCurrentLang();
  const indexKey = getAlgoliaIndexBaseKey(countryCode, lang);
  const sortByOptions = {
    "": "default",
    all: "default",
    new: "new-arrivals",
    low_high: "price-low-high",
    high_low: "price-high-low",
  };
  const sort = sortByOptions[sortBy] || "default";
  const index = sort === "default" ? `products-${indexKey}` : `products-${indexKey}-${sort}`;

  const headerHeight = useMemo(() => {
    if (isBrowser) {
      return document.querySelector(".header").offsetHeight;
    }
    return null;
  }, []);

  const style = useResponsiveWithHydrationFix({ top: 0 }, { top: -headerHeight });

  return (
    <InstantSearch searchClient={searchClient} indexName={index}>
      <Configure clickAnalytics />
      <div className={Styles.searchPopup} style={style}>
        <CustomSearchUI
          sortBy={sortBy}
          onChangeSortBy={setSortBy}
          indexName={index}
          closeSearch={closeSearch}
        />
      </div>
    </InstantSearch>
  );
}
SearchPopup.propTypes = {
  closeSearch: PropTypes.func,
};

export default withALErrorBoundary({
  name: "SearchPopup",
  priority: "P2",
})(SearchPopup);
