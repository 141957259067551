import { graphql, useStaticQuery } from "gatsby";
import { useState, useRef, useEffect, useContext } from "react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

import { roundNumber, gaCustomEvent, edgesToArray } from "../context/helpers";
import StoreContext from "../context/store";
import { DiscountContext } from "../context/DiscountProvider";
import { CartContext } from "../context/CartProvider";
import { GeoContext } from "../context/GeoProvider";
import { LuxeContext } from "../context/LuxeProvider";
import { useALError, withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { HiddenElementsContext } from "../context/HiddenElements";
import { useTracking } from "../context/Tracking";

import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import { useShippingInfo } from "../hooks/useShippingInfo";
import { usePrevious } from "../hooks/usePrevious";
import useSalesColors from "../hooks/useSalesColors";
import useDeviceDetect from "../hooks/useDeviceDetect";

import { ALButton, ALLoading, ALLink } from "./ALComponents";
import { applyCartDiscounts } from "./cartDiscounts";
import { ComponentType } from "../constants/ComponentType";
import TAG from "../constants/Tag";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

import Banner from "./sideCart/Banner";
import CheckoutButton from "./sideCart/CheckoutButton";
import GiftWithPurchaseProgressBar from "./sideCart/GiftWithPurchaseProgressBar";
import QuantityPercentProgressBar from "./al_components/QuantityPercentProgressBar";
import IconCloseSideCart from "./icons/svgs/close-round.svg";

import LuxeSelection from "./sideCart/LuxeSelection";
import SideCartItems from "./sideCart/SideCartItems";
import UpSellsRecommendation from "./sideCart/UpSellsRecommendation";
import PriceSummary from "./sideCart/PriceSummary";

import "./sideCart/styles.scss";

SwiperCore.use([Navigation, Pagination, A11y]);

function SideCart() {
  const salesColors = useSalesColors();
  const context = useContext(StoreContext);
  const { discountInfo } = useContext(DiscountContext);
  const {
    luxe: { isLuxeActive, hasLuxeInCart, shouldAddLuxeInNextATC },
  } = useContext(LuxeContext);
  const { cart, isLoadingCart, itemsInCart, toggleCart, isSideCartOpen } = useContext(CartContext);
  const hasBeenOpenOnce = useRef(false);
  const { trackCartProduct } = context.store;

  const { sendReport } = useALError();
  const { trackOpenSideCart } = useTracking();
  const { gePriceDetails } = useContext(GeoContext);
  const dict = useLocalizedSentenceDict();
  const shippingInfo = useShippingInfo();
  const { show, hide } = useContext(HiddenElementsContext);
  const prevSideCartOpen = usePrevious(isSideCartOpen);
  const { isMobile } = useDeviceDetect();

  const [_finalAmount, setFinalAmout] = useState(0);
  const [shouldSendEvent, setShouldSendEvent] = useState(false);
  const [isNavigatingToCheckout, setIsNavigatingToCheckout] = useState(false);

  const sideCartHeaderRef = useRef(null);
  const sideCartCheckoutRef = useRef(null);

  const countryCode = gePriceDetails.CountryCode;

  if (!hasBeenOpenOnce.current && isSideCartOpen) {
    hasBeenOpenOnce.current = true;
  }

  const sideCartInfo = applyCartDiscounts(cart.lines, discountInfo);
  const { lines } = sideCartInfo;

  useEffect(() => {
    try {
      if (isSideCartOpen) {
        hide();
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        show();
        document.getElementsByTagName("body")[0].style.overflow = null;
      }
    } catch (error) {
      console.log("Error sidecart set style");
      console.log(error);
    }
  }, [isSideCartOpen]);

  useEffect(() => {
    if (sideCartInfo.finalAmount !== 0 && sideCartInfo.finalAmount !== _finalAmount) {
      setFinalAmout(sideCartInfo.finalAmount);
    }
    if (!prevSideCartOpen && isSideCartOpen) {
      setShouldSendEvent(true);
    }
  }, [sideCartInfo, isLoadingCart]);

  useEffect(() => {
    try {
      if (shouldSendEvent && !isLoadingCart) {
        trackOpenSideCart(sideCartInfo, gePriceDetails.CurrencyCode);
        setShouldSendEvent(false);
      }
    } catch (error) {
      sendReport(error, { name: "SideCart", priority: "P5" });
    }
  }, [isLoadingCart, shouldSendEvent]);

  const isCheckoutDisabled =
    lines.length === 1 &&
    lines[0].variant.product.tags.includes(TAG.MYSTERY) &&
    lines[0].quantity === 1;

  const showTieredProgressTop =
    lines.length !== 0 && discountInfo?.type === DISCOUNT_TYPES.QUANTITY_PERCENT;

  const isLuxe = isLuxeActive || hasLuxeInCart || shouldAddLuxeInNextATC;
  const estimatedTotal = roundNumber(
    isLuxe ? sideCartInfo.finalAmountLux : sideCartInfo.finalAmount
  );

  const goToCheckout = async (url) => {
    setIsNavigatingToCheckout(true);

    trackCartProduct.forEach((productTracked) => {
      if (productTracked.origin.startsWith("recommended-")) {
        const readableFormatOrigin = productTracked.origin
          .replace("recommended-", "")
          .split("-")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
        gaCustomEvent(
          `Recommended Product - ${readableFormatOrigin}`,
          "go-to-checkout",
          productTracked.productTitle
        );
      }
    });

    window.location.href = url;

    // Set the loading state to false after a few seconds, which is the average time to redirect to checkout
    // This prevents the loader from being stuck when the user swipes back from checkout on mobile browsers
    setTimeout(() => {
      setIsNavigatingToCheckout(false);
    }, 7000);
  };

  const backgroundColor =
    (salesColors && (isLuxe ? salesColors?.tierBgColorLuxe : salesColors?.tierBgColorGuest)) ||
    "#000";

  const sideCartTags = useStaticQuery(graphql`
    query {
      allContentfulSideCartContent(
        filter: { node_locale: { eq: "en-US" }, tagHeader: { ne: "null" } }
      ) {
        edges {
          node {
            tagHeader
            textColorTag {
              mobile
              desktop
            }
            backgroundColorTag {
              mobile
              desktop
            }
          }
        }
      }
    }
  `);
  const sideCartTag = edgesToArray(sideCartTags.allContentfulSideCartContent) || null;

  return (
    <div className="al_sidecart">
      <div
        onClick={toggleCart}
        className={`sidecart_container ${
          isSideCartOpen ? "sidecart_container--isOpen" : "sidecart_container--isClose"
        }`}
      />
      <div className={`sidecart ${isSideCartOpen ? "sidecart--isOpen" : "sidecart--isClose"}`}>
        {isSideCartOpen && (isLoadingCart || isNavigatingToCheckout) && (
          <div className="sidecart_loader">
            <ALLoading />
          </div>
        )}

        <div className="sidecart_header" ref={sideCartHeaderRef}>
          <span className="sidecart_header_title">{dict.get("Your cart")}</span>
          {itemsInCart !== 0 && <span className="sidecart_qty">{`(${itemsInCart})`}</span>}

          {sideCartTag && sideCartTag.length > 0 && (
            <div
              className="sidecart_header_tag"
              style={{
                backgroundColor: isMobile
                  ? sideCartTag[0].backgroundColorTag.mobile
                  : sideCartTag[0].backgroundColorTag.desktop,
                color: isMobile
                  ? sideCartTag[0].textColorTag.mobile
                  : sideCartTag[0].textColorTag.desktop,
                border: `1px solid ${
                  isMobile
                    ? sideCartTag[0].backgroundColorTag.mobile
                    : sideCartTag[0].backgroundColorTag.desktop
                }`,
              }}
            >
              <span>{sideCartTag[0].tagHeader}</span>
            </div>
          )}

          <div className="sidecart_close" onClick={toggleCart}>
            <IconCloseSideCart fill="none" stroke="#2D2927" />
          </div>
        </div>

        {discountInfo?.freegift && (
          <GiftWithPurchaseProgressBar
            value={Number(sideCartInfo.finalAmount)}
            giftData={discountInfo?.freegift}
            shippingThreshold={shippingInfo?.freeShippingThreshold}
          />
        )}

        {itemsInCart === 0 && !isLoadingCart ? (
          <div className="sidecart_bag_empty">
            <p className="h2">{dict.get("Your cart is empty")}.</p>
            <div className="sidecart_bag_empty_links">
              <ALLink to="/collections/bestsellers" className="sidecart_bag_empty_links__link">
                <ALButton variant="secondary" onClick={toggleCart} fullWidth>
                  {dict.get("Best sellers")}
                </ALButton>
              </ALLink>
              <ALLink to="/collections/new" className="sidecart_bag_empty_links__link">
                <ALButton variant="secondary" onClick={toggleCart} fullWidth>
                  {dict.get("New Arrivals")}
                </ALButton>
              </ALLink>
            </div>
          </div>
        ) : (
          <>
            <div
              className="sidecart_content"
              style={{
                paddingBottom: showTieredProgressTop
                  ? 0
                  : `${sideCartCheckoutRef?.current?.clientHeight}px`,
              }}
            >
              <LuxeSelection sideCartInfo={sideCartInfo} discountInfo={discountInfo} />

              <SideCartItems
                lineItems={lines}
                context={context}
                toggleCart={toggleCart}
                freegiftLineItemId={sideCartInfo.freegiftLineItemId}
                isLuxe={isLuxe}
              />

              {hasBeenOpenOnce.current && <UpSellsRecommendation lines={lines} />}

              <PriceSummary
                sideCartInfo={sideCartInfo}
                shippingInfo={shippingInfo}
                discountInfo={discountInfo}
                countryCode={countryCode}
                isLuxe={isLuxe}
              />
            </div>

            {showTieredProgressTop ? (
              <div
                className="sidecart_tiered_content checkout_cta_tiered_discount"
                style={{ backgroundColor }}
              >
                <QuantityPercentProgressBar
                  eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
                  componentType={ComponentType.SIDE_CART}
                  isLuxe={isLuxe}
                  saleColors={salesColors}
                />
                <CheckoutButton
                  checkoutUrl={cart.checkoutUrl}
                  isCheckoutDisabled={isCheckoutDisabled}
                  estimatedTotal={estimatedTotal}
                  fullAmount={sideCartInfo.fullAmount}
                  countryCode={countryCode}
                  goToCheckout={goToCheckout}
                  isLuxe={isLuxe}
                  saleColors={salesColors}
                />
              </div>
            ) : (
              <Banner
                sideCartInfo={sideCartInfo}
                discount={discountInfo}
                country={countryCode}
                shippingThreshold={shippingInfo?.freeShippingThreshold}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withALErrorBoundary({
  name: "SideCart",
  priority: "P1",
})(SideCart);
