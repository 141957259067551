import { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import LazyLoad from "react-lazyload";

import { GeoContext } from "../context/GeoProvider";
import { gaCustomEvent, edgesToArray } from "../context/helpers";

import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";

import { ALLink as Link } from "./ALComponents";
import AutoScroll from "./al_components/AutoScroll";

function QuickAccess() {
  const { gePriceDetails } = useContext(GeoContext);
  const location = useLocation();
  const dict = useLocalizedSentenceDict();

  const onMenuClick = (label, hasImage) => {
    const eventCategory = hasImage ? " - Quick Access With Image" : " - Quick Access";
    gaCustomEvent(`Menu Link${eventCategory}`, "Click", label);
  };

  const renderQuickAccessMenu = (content) => {
    const { collections } = content;

    // Confirm withImages boolean is true && each collection entry has an associated image:
    const withImages =
      content?.withImages && collections.every((ele) => !!ele?.image?.mobileImage?.gatsbyImageData);

    const result = [];
    collections?.forEach((entry) => {
      const { id, title, tag, tagBackgroundColor } = entry;
      const handle = entry?.collection?.handle;
      const imageSrc = withImages ? getImage(entry.image.mobileImage.gatsbyImageData) : null;
      const altText = entry?.image?.mobileImage?.altText || title;
      let slug = null;

      if (location?.pathname) {
        const path = location.pathname.split("/").filter((part) => part);
        slug = path[path.length - 1];
      }

      result.push(
        <Link
          key={id}
          className={withImages ? "quick_access_img_container" : "quick_access_link"}
          to={`/collections/${handle}`}
          onClick={() => onMenuClick(title, withImages)}
        >
          {withImages && (
            <>
              {tag && tag !== "null" && (
                <span className="quick_access_tag" style={{ backgroundColor: tagBackgroundColor }}>
                  {dict.get(tag)}
                </span>
              )}
              <GatsbyImage
                loading="eager"
                image={imageSrc}
                alt={altText}
                className="quick_access_img"
                style={tag && tag !== "null" ? { border: `1px solid ${tagBackgroundColor}` } : {}}
              />
              <span className={`quick_access_link_text ${slug === handle ? "bold" : ""}`}>
                {title}
              </span>
            </>
          )}
          {!withImages && title}
        </Link>
      );
    });

    return (
      <LazyLoad offset={0} height={111} className="quick_access_container">
        <AutoScroll speed={2} draggable gap="7px" delay={2000}>
          <div className={`quick_access${withImages ? "--with-images" : ""}`}>{result}</div>
        </AutoScroll>
      </LazyLoad>
    );
  };

  const data = useStaticQuery(graphql`
    query {
      allContentfulQuickAccess {
        edges {
          node {
            node_locale
            collections {
              image {
                altText
                mobileImage {
                  gatsbyImageData(width: 72)
                }
              }
              id
              title
              tag
              tagBackgroundColor
              collection {
                handle
              }
            }
            withImages
            countries {
              code
            }
          }
        }
      }
    }
  `);

  let quickAccess = null;
  if (data.allContentfulQuickAccess?.edges && data.allContentfulQuickAccess?.edges.length > 0) {
    const allQuickAccess = edgesToArray(data.allContentfulQuickAccess);
    const defaultMenu = allQuickAccess.find((n) => n?.countries?.some((c) => c.code === "RoW"));
    const localeSpecificQuickAccess = allQuickAccess.filter((n) =>
      n?.countries?.some((c) => c.code === gePriceDetails?.CountryCode)
    );
    const localeSpecificQuickAccessRoot = localeSpecificQuickAccess.find(
      (b) => b.node_locale === gePriceDetails?.Culture
    );
    quickAccess = localeSpecificQuickAccessRoot ?? defaultMenu;
  }

  if (quickAccess) {
    return renderQuickAccessMenu(quickAccess);
  }

  return null;
}

export default withALErrorBoundary({
  name: "QuickAccess",
  priority: "P3",
})(QuickAccess);
