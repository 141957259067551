import IconArrow from "../icons/svgs/arrow.svg";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../constants/ComponentType";
import { useTracking } from "../../context/Tracking";
import Dropdown from "../Dropdown";

function SortsCollection({ sortingType, onSort, className, componentType }) {
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();
  const { trackCollectionSortClick } = useTracking();
  const sortLabel = componentType === ComponentType.BYOB ? "SORT BY" : "SORT";

  const sortOptions = [
    { label: dict.get("Best sellers"), value: "all" },
    { label: dict.get("New Arrivals"), value: "new" },
    { label: dict.get("Prices: low to high"), value: "low_high" },
    { label: dict.get("Prices: high to low"), value: "high_low" },
  ];

  const handleOnSort = (value) => {
    onSort(value);
    trackCollectionSortClick(value);
  };

  return isMobile ? (
    <div className={`${className ? `${className}` : null}`}>
      <select
        className="subtext"
        name={sortLabel}
        onChange={(e) => handleOnSort(e.target.value)}
        defaultValue={sortingType || ""}
      >
        {[{ label: dict.get(sortLabel), value: "" }, ...sortOptions].map((s) => (
          <option key={`sorting_select_${s.value}`} className="al_p" value={s.value}>
            {s.label}
          </option>
        ))}
      </select>
      <span className="collection_sort_icon">
        <IconArrow height="11" width="11.8" className="collection_dropdown_arrow" />
      </span>
    </div>
  ) : (
    <Dropdown
      options={sortOptions}
      onSelect={handleOnSort}
      sortingType={sortingType}
      className={className}
    />
  );
}

export default withALErrorBoundary({
  name: "SortsCollection",
  priority: "P2",
})(SortsCollection);
