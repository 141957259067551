import { getCookie, isBrowser, hashEmail } from "../helpers";

function processAccountDetail(accountDetails) {
  const userProperties = {};
  userProperties.user_consent = "";

  if (!accountDetails) {
    userProperties.visitor_type = "guest";
    return userProperties;
  }

  userProperties.customer_email = accountDetails.email;
  userProperties.visitor_type = "logged_in";
  if ("firstName" in accountDetails) {
    userProperties.customer_first_name = accountDetails.firstName;
  }
  if ("lastName" in accountDetails) {
    userProperties.customer_last_name = accountDetails.lastName;
  }
  userProperties.customer_order_count = accountDetails?.orders?.edges?.length.toString() || "0";
  userProperties.customer_id = hashEmail(accountDetails.email);

  return userProperties;
}

function triggerElevarEvent({ eventName, ecommerceData, accountDetails, email, cartTotal = null }) {
  let user = null;
  if (accountDetails) {
    user = processAccountDetail(accountDetails);
  }

  const elevarData = {
    event: eventName,
  };

  if (user) {
    elevarData.user_properties = user;
  } else if (email) {
    elevarData.user_properties = {
      customer_email: email,
      customer_id: hashEmail(email),
      visitor_type: "logged_in",
    };
  }

  if (ecommerceData) {
    elevarData.ecommerce = ecommerceData;
  }

  if (cartTotal) {
    elevarData.cart_total = cartTotal;
  }

  if (isBrowser) {
    window.ElevarDataLayer?.push(elevarData);
  }
}

// Customer creates new account
// If your users don’t typically sign up this event is not required
export function elevarSendSignUp({ email }) {
  triggerElevarEvent({
    eventName: "dl_sign_up",
    ecommerceData: null,
    accountDetails: null,
    email,
  });
}

// Customer Logs into their account
// If your users don’t typically login this event is not required
export function elevarSendLogin({ email }) {
  triggerElevarEvent({
    eventName: "dl_login",
    ecommerceData: null,
    accountDetails: null,
    email,
  });
}

// Search page product impressions
export function elevarSendSearch({ products, accountDetails }) {
  const items = products.reduce((red, p) => {
    const _variant = p?.variants[0];
    red.push({
      id: _variant?.sku ?? "",
      name: p?.title,
      brand: "Ana Luisa",
      category: p?.productType,
      variant: _variant.title,
      price: _variant?.priceV2?.amount,
      quantity: "1",
      list: "",
      product_id: p?.id.replace("gid://shopify/Product/", ""),
      variant_id: _variant?.id.replace("gid://shopify/ProductVariant/", ""),
      compare_at_price: _variant?.compareAtPriceV2 ? _variant?.compareAtPriceV2?.amount : "",
      position: red.length + 1,
    });
    return red;
  }, []);

  const ecommerceData = {
    currencyCode: "USD",
    actionField: {
      list: "search results",
    },
    impressions: items, // See impressions array below
  };

  triggerElevarEvent({
    eventName: "dl_view_search_results",
    ecommerceData,
    accountDetails,
    email: null,
  });
}

// Collection page product impressions
export function elevarSendViewPLP({ url, products, accountDetails }) {
  const items = products.reduce((red, p) => {
    const variant = p?.variants[0];
    red.push({
      id: variant?.sku ?? "",
      name: p?.title,
      brand: "Ana Luisa",
      category: p?.productType,
      variant: variant.title,
      price: variant?.final_price,
      quantity: "1",
      list: url,
      product_id: p?.id.replace("gid://shopify/Product/", ""),
      variant_id: variant?.id.replace("gid://shopify/ProductVariant/", ""),
      compare_at_price: variant?.full_price,
      position: red.length + 1,
    });
    return red;
  }, []);

  const ecommerceData = {
    currencyCode: "USD",
    impressions: items, // See impressions array below
  };

  triggerElevarEvent({
    eventName: "dl_view_item_list",
    ecommerceData,
    accountDetails,
    email: null,
  });
}

export function elevarViewProduct({ product, accountDetails }) {
  const plpURL = getCookie("bread_url");
  const variant = product?.variants[0];

  const ecommerceData = {
    currencyCode: product?.currencyCode,
    detail: {
      actionField: {
        list: "", // this should be the collection page URL that user clicked product from
        action: "detail",
      },
      products: [
        {
          id: variant?.sku,
          name: product?.title,
          brand: "Ana Luisa",
          category: product?.productType,
          variant: variant.title,
          price: product?.final_price?.toString(),
          quantity: "1",
          position: 1,
          list: plpURL ?? "",
          product_id: product?.id.replace("gid://shopify/Product/", ""),
          variant_id: variant?.id.replace("gid://shopify/ProductVariant/", ""),
          compare_at_price: product?.full_price?.toString(),
          image: product?.images[0]?.url,
          inventory: "",
        },
      ],
    },
  };

  triggerElevarEvent({
    eventName: "dl_view_item",
    ecommerceData,
    accountDetails,
    email: null,
  });
}

// Add to Cart // note the list object which carries from collection page
export function elevarSendAddToCart({
  category,
  currency,
  price,
  productId,
  variantId,
  quantity,
  title,
  sku,
  accountDetails,
  images,
}) {
  const plpURL = getCookie("bread_url");

  const ecommerceData = {
    currencyCode: currency,
    add: {
      actionField: {
        list: plpURL || "", // this should be the collection page URL that user clicked product from
      },
      products: [
        {
          id: sku,
          name: title,
          brand: "Ana Luisa",
          category,
          variant: title,
          price: price?.toString(),
          quantity: quantity.toString(),
          list: plpURL || "",
          product_id: productId,
          variant_id: variantId,
          compare_at_price: "0.0",
          image: images?.[0]?.url,
        },
      ],
    },
  };

  triggerElevarEvent({
    eventName: "dl_add_to_cart",
    ecommerceData,
    accountDetails,
    email: null,
  });
}

// Remove from Cart // note the list object which carries from collection page
export function elevarSendRemoveFromCart({
  lineItem,
  title,
  productId,
  category,
  currency,
  quantity,
  accountDetails,
}) {
  const ecommerceData = {
    currencyCode: currency,
    remove: {
      actionField: {
        list: "",
      },
      products: [
        {
          id: lineItem.variant.sku,
          name: title,
          brand: "Ana Luisa",
          category: category ?? "Other",
          variant: lineItem.variant.title,
          price: lineItem.variant.final_price.toString(),
          quantity: quantity.toString(),
          list: "",
          product_id: productId,
          variant_id: lineItem.variant.id.replace("gid://shopify/ProductVariant/", ""),
          compare_at_price: lineItem.variant.full_price.toString(),
          image: lineItem.variant.image.url,
        },
      ],
    },
  };

  triggerElevarEvent({
    eventName: "dl_remove_from_cart",
    ecommerceData,
    accountDetails,
    email: null,
  });
}

// View Cart/Mini Cart
export function elevarSendViewCart({ cart, currency, accountDetails }) {
  const items = cart?.lines.reduce((red, item) => {
    const cartItem = {
      id: item?.variant?.sku, // SKU
      name: item?.variant?.product?.title, // Product title
      brand: "Ana Luisa",
      category: item?.variant?.product?.productType,
      variant: item?.variant?.product?.title,
      price: item?.final_price.toString(),
      quantity: item?.quantity.toString(), // Only required for dl_view_cart
      product_id: item?.variant?.product?.id.replace("gid://shopify/Product/", ""), // The product_id
      variant_id: item?.variant?.id.replace("gid://shopify/ProductVariant/", ""), // id or variant_id
      position: red.length + 1, // position in the list of search results, collection views and position in cart indexed starting at 1
    };
    if (item?.final_price !== item?.full_price) {
      cartItem.compare_at_price = item?.full_price.toString();
    }
    red.push(cartItem);
    return red;
  }, []);

  const ecommerceData = {
    currencyCode: currency,
    actionField: {
      list: "Shopping Cart",
    },
    impressions: items, // See impressions array below
  };

  triggerElevarEvent({
    eventName: "dl_view_cart",
    ecommerceData,
    accountDetails,
    email: null,
    cartTotal: cart?.finalAmount.toString(),
  });
}

export function elevarSendPageView() {
  if (!isBrowser || typeof window.ElevarDataLayer === "undefined") {
    return false;
  }
  const email = isBrowser && window.exponea?.email_id;
  const eventData = { eventName: "dl_page_view" };
  if (email) {
    eventData.email = email;
  }

  triggerElevarEvent(eventData);
  return true;
}
