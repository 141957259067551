import { useMemo } from "react";
import PropTypes from "prop-types";
import { parseISO, format } from "date-fns";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import ButtonsAddToCart from "../ButtonsAddToCart";

import TAG from "../../../constants/Tag";
import { InventoryPolicies } from "../../../constants/InventoryPolicies";

function getPreOrderShipDate(product) {
  try {
    const parsedDate = parseISO(product?.preOrder?.shippingDate);
    return format(parsedDate, "MMM d, yyyy");
  } catch (error) {
    console.error(error);
    return null;
  }
}

function AddToCart({
  product,
  variantSelectedOptions,
  componentType,
  engravableText = "",
  engravableFonts = "",
  searchObject,
  fallBackFunction,
  prices,
  additionalProducts = [],
  addProductCallback,
}) {
  const {
    handle,
    title,
    variants,
    productType,
    tags,
    engravable: { isEngravable } = {},
    images,
  } = product;
  const variant = variants?.find(
    (x) => x.title === variantSelectedOptions[product.options[0].name]
  );

  const engraveDetails =
    engravableText !== "" && engravableFonts !== ""
      ? {
          font: engravableFonts,
          text: engravableText,
        }
      : null;

  const isPreOrder = product.isPreOrder && variant?.inventoryPolicy === InventoryPolicies.CONTINUE;

  const shippingDate = isPreOrder ? getPreOrderShipDate(product) : null;

  const additionalProductsToAdd = useMemo(
    () =>
      additionalProducts.map((additionalProduct) => {
        const additionalProductVariant = additionalProduct.product.variants?.[0]; // only support one variant for now
        const isAdditionalProductPreOrder =
          additionalProduct.product.isPreOrder &&
          additionalProductVariant?.inventoryPolicy === InventoryPolicies.CONTINUE;
        return {
          productHandle: additionalProduct.product.handle,
          images: additionalProduct.product.images,
          isPreOrder: isAdditionalProductPreOrder,
          preOrderShipDate: isAdditionalProductPreOrder
            ? getPreOrderShipDate(additionalProduct.product)
            : null,
          productId: additionalProduct.product?.shopifyId || additionalProduct.product.id,
          title: additionalProduct.product.title,
          sku: additionalProduct.product.variants?.[0]?.sku || "NULL",
          variant: additionalProductVariant,
          engraveDetails: additionalProduct.engraveDetails,
          isEngravable: additionalProduct.product.engravable?.isEngravable,
          isFinalSale: additionalProduct.product.tags?.includes(TAG.FINAL_SALE),
          category: additionalProduct.product.productType,
          prices: additionalProduct.prices,
        };
      }),
    [additionalProducts]
  );

  return (
    <ButtonsAddToCart
      productHandle={handle}
      images={images}
      isPreOrder={isPreOrder}
      preOrderShipDate={shippingDate}
      variant={variant}
      title={title}
      sku={variant?.sku || "NULL"}
      category={productType}
      productId={product?.shopifyId || product?.id}
      isEngravable={isEngravable}
      engraveDetails={engraveDetails}
      isFinalSale={tags?.includes(TAG.FINAL_SALE)}
      componentType={componentType}
      searchObject={searchObject}
      fallBackFunction={fallBackFunction}
      prices={prices}
      additionalProducts={additionalProductsToAdd}
      addProductCallback={addProductCallback}
    />
  );
}

AddToCart.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    handle: PropTypes.string,
    title: PropTypes.string,
    isPreOrder: PropTypes.bool,
    shopifyId: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    tags: PropTypes.arrayOf(PropTypes.string),
    variants: PropTypes.arrayOf(
      PropTypes.shape({ sku: PropTypes.string, title: PropTypes.string })
    ),
    engravable: PropTypes.shape({
      isEngravable: PropTypes.bool,
    }),
    productType: PropTypes.string,
  }),
  engravableText: PropTypes.string,
  engravableFonts: PropTypes.string,
  componentType: PropTypes.string,
  variantSelectedOptions: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  fallBackFunction: PropTypes.func,
  prices: PropTypes.object,
  additionalProducts: PropTypes.array,
};

export default withALErrorBoundary({
  name: "AddToCart",
  priority: "P1",
})(AddToCart);
