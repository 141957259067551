import { PropTypes } from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import useResponsiveWithHydrationFix from "../../../../../hooks/useResponsiveWithHydrationFix";
import { useLocalizedSentenceDict } from "../../../../../hooks/useSentenceDict";

import * as Styles from "./searchLinks/styles.module.scss";

function SearchLinksElement({ image }) {
  const { mobileImage, desktopImage, altText, link } = image;
  const imageSource = useResponsiveWithHydrationFix(
    mobileImage.gatsbyImageData,
    desktopImage.gatsbyImageData
  );
  return (
    <Link to={link.link.link}>
      <GatsbyImage image={imageSource} alt={altText} />
      <p className="subtext">{link.title}</p>
    </Link>
  );
}

function SearchLinks({ links, nbHits, currentRefinement }) {
  const dict = useLocalizedSentenceDict();

  if (!links?.length) {
    return null;
  }

  let additionalClassName = "firstPage";
  if (currentRefinement) {
    additionalClassName = "secondPage";
  }

  return (
    <div className={`${Styles.linksCarouselContainer} ${Styles[additionalClassName]}`}>
      {nbHits === 0 && (
        <h3 className="subtext subtext--bold subtext--uc">{dict.get("Are you looking for...")}</h3>
      )}
      <div className={Styles.linksCarousel} data-testid="search-links">
        {links.map((image) => (
          <SearchLinksElement key={image.link.title} image={image} />
        ))}
      </div>
    </div>
  );
}

SearchLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      mobileImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      desktopImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      altText: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.shape({ link: PropTypes.string }),
      }),
    })
  ),
};

export default SearchLinks;
