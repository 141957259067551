import { useMemo, useContext } from "react";
import PropTypes from "prop-types";

import { DiscountContext } from "../../context/DiscountProvider";
import { LuxeContext } from "../../context/LuxeProvider";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ComponentType } from "../../constants/ComponentType";
import COLORS from "../../constants/Colors";
import { Country } from "../../constants/Country";

import ProgressBar from "./ProgressBar";
import { IconFreeShipping } from "../ALIcons";

import * as Styles from "./quantityPercentProgressBar/styles.module.scss";

function QuantityPercentProgressBar({
  eligibleItemCount,
  componentType,
  announcementTitleElements = "",
  isLuxe,
  saleColors,
}) {
  const { luxe } = useContext(LuxeContext);
  const { discountInfo } = useContext(DiscountContext);
  const dict = useLocalizedSentenceDict();

  const countryCode = useCurrentCountryCode();
  const isUS = countryCode === Country.US;
  const isSideCart = componentType === ComponentType.SIDE_CART;
  const isBottomBanner = componentType === ComponentType.TIER_DISCOUNT_BOTTOM_BANNER;
  const isAnnouncementBar = componentType === ComponentType.ANNOUNCEMENT_BAR;

  let textColor = null;
  let textInverseColor = null;
  let backgroundColor = null;

  textColor =
    (saleColors && (isLuxe ? saleColors?.tierTextColorLuxe : saleColors?.tierTextColorGuest)) ||
    COLORS.BLACK;

  textInverseColor =
    (saleColors &&
      (isLuxe ? saleColors?.tierInverseTextColorLuxe : saleColors?.tierInverseTextColorGuest)) ||
    COLORS.BLACK;

  backgroundColor =
    (saleColors && (isLuxe ? saleColors?.tierBgColorLuxe : saleColors?.tierBgColorGuest)) ||
    COLORS.BLACK;

  const discountSections = useMemo(
    () =>
      discountInfo.discounts.map((tier, i) => {
        // Progress value is a always a percentage of 100, as maxValue is always 100 for our progress bars
        let progressValue = 0;

        // First tier can have multiple items to reach the discount
        if (i === 0) {
          progressValue = Math.min((100 * eligibleItemCount) / tier[0], 100);

          // Other tiers are always 1 item away from the next discount
        } else if (eligibleItemCount >= tier[0]) {
          progressValue = 100;
        }

        let steps = [];
        // Steps for the progress bar in this discount section
        // First progress bar can contain icons in its steps if it's US and icon can fit (less than 3 tiers)
        if (i === 0 && isUS && discountInfo.discounts.length === 2) {
          const stepValue = 100 / tier[0];
          steps = [
            {
              value: stepValue, // maxValue is always 100%, so icon is displayed on first "item"
              icon: (
                <IconFreeShipping stroke={progressValue >= stepValue ? COLORS.OAT : COLORS.BLACK} />
              ),
            },
          ];
        }

        return {
          progressValue,
          percentageOff: luxe.isLuxeEnabled && isLuxe ? tier[1] + luxe.discountLuxe * 100 : tier[1],
          itemCount: tier[0],
          steps,
        };
      }),
    [discountInfo.discounts, eligibleItemCount, isUS, luxe, isLuxe]
  );

  let announcementBarTitle =
    discountSections.length === 2
      ? `${
          announcementTitleElements &&
          announcementTitleElements !== "null" &&
          announcementTitleElements
        } BUY ${discountSections[1].itemCount}+, GET ${discountSections[1].percentageOff}% OFF`
      : announcementTitleElements || "";

  let sideCartTitle = null;
  let sideCartSubtitle = null;

  // sideCartTitle defaults to announcementBarTitle
  sideCartTitle = announcementBarTitle;

  // cart has eligible items, but not enough to reach the first discount
  if (eligibleItemCount !== 0 && eligibleItemCount <= discountSections[0].itemCount) {
    if (isUS) {
      sideCartTitle = dict.get("YOU'VE UNLOCKED FREE SHIPPING!");
      sideCartSubtitle = dict.get(
        `You are 1 item away from **{0}% OFF**`,
        discountSections[0].percentageOff
      );
      announcementBarTitle = sideCartSubtitle;
    } else {
      sideCartTitle = dict.get(
        `You are 1 item away from {0}% OFF`,
        discountSections[0].percentageOff
      );
      announcementBarTitle = sideCartTitle;
    }
  }

  // intermediary tiers
  discountSections.forEach((discount, i) => {
    if (
      eligibleItemCount >= discount.itemCount &&
      eligibleItemCount < discountSections[discountSections.length - 1].itemCount
    ) {
      announcementBarTitle = dict.get(`YOU'VE UNLOCKED ${discount.percentageOff}% OFF`);
      sideCartTitle = announcementBarTitle;

      sideCartSubtitle = dict.get(
        `You are 1 item away from **{0}% OFF**`,
        discountSections[i + 1].percentageOff
      );

      if (discountSections.length === 2) {
        announcementBarTitle = sideCartSubtitle;
      }

      if (isUS) {
        sideCartTitle = `${sideCartTitle} + FREE SHIPPING`;
      }
    }
  });

  // cart reached the final discount
  if (eligibleItemCount >= discountSections[discountSections.length - 1].itemCount) {
    announcementBarTitle = dict.get(
      `CONGRATS! YOU'VE UNLOCKED {0}% OFF`,
      discountSections[discountSections.length - 1].percentageOff
    );
    sideCartTitle = announcementBarTitle;
    sideCartSubtitle = null;
  }

  return (
    <div className={`${isLuxe ? Styles.luxeActive : Styles.luxeInactive}`}>
      {isSideCart && (
        <div
          className={`${Styles.sidecartTitle} subtext subtext--uc subtext--bold mb-5`}
          style={{ color: textColor }}
        >
          {sideCartTitle}
        </div>
      )}

      <div
        className={`${Styles.progressContainer} ${
          discountSections.length === 2 ? Styles.progressContainerTwoTier : ""
        }`}
      >
        {discountSections.map((section, i) => (
          <div
            key={`progress-bar-${i}`}
            className={`${Styles.tierContainer} ${
              isBottomBanner ? Styles.tierContainerBottomBanner : ""
            }`}
          >
            <ProgressBar
              type="regular"
              value={section.progressValue}
              maxValue={100}
              steps={section.steps}
              componentType={componentType}
              isLuxe={isLuxe}
              backgroundColor={section.progressValue === 100 ? textColor : backgroundColor}
              backgroundColorCompleted={saleColors?.tierTextColorLuxe}
            />
            <div
              className={`${Styles.discount} ${
                section.progressValue === 100 ? Styles.discountActive : ""
              } ${isLuxe ? Styles.luxeActive : ""}`}
              style={{
                color: section.progressValue === 100 ? textInverseColor : "#000000",
                backgroundColor: section.progressValue === 100 ? textColor : backgroundColor,
              }}
            >
              <span className={Styles.discountText}>{`${section.percentageOff}% OFF`}</span>
            </div>
          </div>
        ))}
      </div>

      {isAnnouncementBar && (
        <div className={Styles.announcementBarTitle} style={{ color: textColor }}>
          {announcementBarTitle}
        </div>
      )}

      {isBottomBanner && (
        <div className={Styles.announcementBarTitleBottomBanner} style={{ color: textColor }}>
          {announcementBarTitle}
        </div>
      )}

      {isSideCart && (
        <div
          className={`${Styles.sidecartSubtitle} ${
            sideCartSubtitle !== null ? "mt-10" : ""
          } subtext`}
        >
          {sideCartSubtitle}
        </div>
      )}
    </div>
  );
}

QuantityPercentProgressBar.propTypes = {
  componentType: PropTypes.string,
  eligibleItemCount: PropTypes.number,
  announcementTitleElements: PropTypes.string,
};

export default QuantityPercentProgressBar;
