import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../constants/ComponentType";

import * as Styles from "./progressBar/styles.module.scss";

const PROGRESS_BAR_TYPES = {
  REGULAR: "regular",
  ROUNDED: "rounded",
};

function ProgressBar({
  value,
  maxValue,
  steps,
  type,
  componentType,
  isLuxe,
  backgroundColor = null,
  backgroundColorCompleted = null,
}) {
  const progress = Math.min((value * 100) / maxValue, 100);
  const hasIcons = steps?.filter((s) => !!s.icon).length > 0;

  const isBottomBanner = componentType === ComponentType.TIER_DISCOUNT_BOTTOM_BANNER;

  let classContainer;

  if (type === PROGRESS_BAR_TYPES.ROUNDED) {
    classContainer = Styles.rounded_container;
  } else if (type === PROGRESS_BAR_TYPES.REGULAR) {
    classContainer = Styles.regular_container;
  } else {
    classContainer = Styles.container;
  }

  return (
    <div
      className={`${classContainer} ${hasIcons ? Styles.withIcons : ""} ${
        isLuxe ? Styles.luxeActive : ""
      }`}
    >
      <div
        className={`${Styles.progress} ${isBottomBanner ? Styles.progressBottomBanner : ""}`}
        style={{
          width: `calc(${progress}% - 2px)`,
          borderRadius: type === PROGRESS_BAR_TYPES.REGULAR && maxValue === value ? "4px" : "",
          backgroundColor: backgroundColorCompleted,
        }}
        data-testid="progress-bar-completed"
      />

      <div
        className={`${Styles.fullBar} ${isBottomBanner ? Styles.fullBarBottomBanner : ""}`}
        style={{ backgroundColor }}
      >
        {steps?.map((step) => {
          const leftPosition = (step.value * 100) / maxValue;
          const isLeft = step.value === 0;
          const isRounded = type === PROGRESS_BAR_TYPES.ROUNDED;

          const pClass = isRounded
            ? isLeft
              ? Styles.label_left
              : Styles.label_right
            : Styles.label;
          const pStyle = isRounded ? {} : { left: `${leftPosition}%` };

          return (
            <div key={step.value}>
              {step.label && (
                <p className={pClass} style={pStyle} data-testid="progress-bar-label">
                  {step.label}
                </p>
              )}
              {hasIcons && (
                <div
                  className={`${Styles.dotContainer} ${
                    isBottomBanner ? Styles.dotContainerBottomBanner : ""
                  } ${value >= step.value && value > 0 ? Styles.completed : ""} `}
                  style={{
                    left: `${leftPosition}%`,
                    backgroundColor:
                      value >= step.value && value > 0 ? backgroundColorCompleted : backgroundColor,
                  }}
                >
                  <div className={Styles.iconContainer} style={{ left: `${leftPosition}%` }}>
                    {step.icon}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      icon: PropTypes.element,
    })
  ),
  type: PropTypes.string,
  componentType: PropTypes.string,
  isLuxe: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "ProgressBar",
  priority: "P3",
})(ProgressBar);
