import { isBrowser } from "../helpers";

const PRODUCT_CATEGORIES = {
  Earrings: 194, // Earrings
  Necklaces: 196, // Necklaces
  Anklets: 189, // Anklets
  "Body Jewelry": 190, // Body Jewelry
  Bundle: 6463, // Jewelry Sets
  Bracelets: 191, // Bracelets
  Rings: 200, // Rings
  "Necklace Charms": 192, // Charms & Pendants
  Jewelry: 188, // Jewelry - default
};

function appLovinPageView() {
  if (!isBrowser || typeof axon === "undefined") return;
  window.axon("track", "page_view");
  return true;
}

function appLovinViewItem({ product }) {
  if (!isBrowser || typeof axon === "undefined") return;
  window.axon("track", "view_item", {
    currency: product.currency || "USD",
    value: parseFloat(product.final_price),
    items: [
      {
        item_variant_id:
          product.variants?.[0]?.id.replace("gid://shopify/ProductVariant/", "") || "",
        item_id: product.id.replace("gid://shopify/Product/", ""),
        item_name: product.title,
        price: parseFloat(product.final_price),
        quantity: 1,
        image_url: product.images?.[0]?.url || "",
        item_category_id: PRODUCT_CATEGORIES[product.productType] || PRODUCT_CATEGORIES.Jewelry,
        discount: product.discountAmount > 0 ? product.discountAmount : 0,
        item_brand: "Ana Luisa",
      },
    ],
  });
}

function appLovinAddToCart({ price, title, variantId, productId, category, currency, images }) {
  if (!isBrowser || typeof axon === "undefined") return;
  window.axon("track", "add_to_cart", {
    currency: currency || "USD",
    value: parseFloat(price),
    items: [
      {
        item_variant_id: variantId.replace("gid://shopify/ProductVariant/", ""),
        item_id: productId,
        item_name: title,
        price: parseFloat(price),
        quantity: 1,
        image_url: images?.[0]?.url || "",
        item_category_id: PRODUCT_CATEGORIES[category] || PRODUCT_CATEGORIES.Jewelry,
        item_brand: "Ana Luisa",
      },
    ],
  });
}

function appLovinRemoveToCart({
  lineItem,
  price,
  title,
  productId,
  variantId,
  category,
  quantity,
}) {
  if (!isBrowser || typeof axon === "undefined") return;
  window.axon("track", "remove_from_cart", {
    currency: price.currency || "USD",
    value: parseFloat(lineItem.final_price),
    items: [
      {
        item_variant_id: variantId,
        item_id: productId,
        item_name: title,
        price: parseFloat(lineItem.final_price),
        quantity: quantity || 1,
        image_url: lineItem?.variant?.image?.url || "",
        item_category_id: PRODUCT_CATEGORIES[category] || PRODUCT_CATEGORIES.Jewelry,
        item_brand: "Ana Luisa",
      },
    ],
  });
}

export { appLovinPageView, appLovinViewItem, appLovinAddToCart, appLovinRemoveToCart };
